<template lang="pug">
  div
    the-loading(v-if="loading")
    v-container(fluid grid-list-md).new-detail
      v-layout.lesslight(row wrap)
        v-flex.new__header_block(xs12)
          v-img.image--blur(
            :src="news.thumb_url"
            :transition="false"
            :lazy-src="require('@/assets/images/placeholder-news.svg')"
          )
          v-container.py-0
            v-img.image(
              :src="news.image"
              contain
              :transition="false"
              :lazy-src="require('@/assets/images/placeholder-news.svg')"
            )
            .new__header
              h1 {{ news.caption }}
              .info-marks
                p.my-2
                  v-icon.marks-icon(small) date_range
                  |  {{ news.date_publication }}
                p.my-2
                  v-icon(small) account_circle
                  |  {{ news.author }}

    v-container.mt-3(grid-list-lg)
      v-layout(row wrap)
        v-flex(xs12)
          .new-content
            p.new-subtitle {{ news.text_short }}
            .new__content_line
            p.mb-4(v-html="news.text_full")

            // YouTube Видео
            p.text-xs-center(
              v-if="news.youtube_links"
              v-for="youtubeLink in news.youtube_links"
              :key="youtubeLink"
            )
              youtube(
                :video-id="youtubeLink"
                width="100%"
                height="600px"
              )

            // Остальные ссылки
            p(
              v-if="news.additional_links"
              v-for="link in news.additional_links"
              :key="link.id"
            )
              a.primary--text(
                v-if="!$youtube.getIdFromUrl(link.link)"
                :href="link.link"
                target="_blank"
              )
                v-icon.mr-2(color="primary" small) attachment
                |  {{ link.name || link.link }}

        v-flex(xs12)
          v-container.px-0.py-0(grid-list-md)
            v-layout(row wrap)
              v-flex(
                xs12 sm4 md4
                v-for="(image, idx) in news.images"
                :key="idx"
              )
                v-img.cursor-pointer(
                  :src="image"
                  contain
                  :transition="false"
                  :lazy-src="require('@/assets/images/placeholder-news.svg')"
                  @click="openImageDialog(image)"
                )

    v-dialog(
      v-model="showImageDialog"
      width="900"
    )
      v-card
        v-card-text
          v-img(
            :src="imageDialogUrl || ''"
            contain
            :lazy-src="require('@/assets/images/placeholder-news.svg')"
          )

</template>

<script>
import NewsService from '@/services/news';
import TheLoading from '@/components/TheLoading';

export default {
  components: { TheLoading },
  props: {
    id: { type: [Number, String], required: true },
  },
  data: () => ({
    news: {},
    loading: false,
    showImageDialog: false,
    imageDialogUrl: null,
  }),
  watch: {
    showImageDialog(val) {
      if (!val) this.imageDialogUrl = null;
    },
  },
  created() {
    this.getInstance();
  },
  methods: {
    getInstance() {
      this.loading = true;
      const params = { expand: 'additional_links,images,thumb_url' };
      this.$rHandler(
        NewsService.getNew(this.id, params),
        ({ data }) => {
          this.news = data.data;

          this.news.image = this.news.images[0] || this.news.thumb_url;
          // первая картинка и так выводится на главной
          this.news.images.shift();

          // Выносим youtube ссылки в отдельное свойство
          this.setYoutubeLinks(this.news.additional_links);
        },
      ).finally(() => { this.loading = false; });
    },
    openImageDialog(url) {
      this.imageDialogUrl = url;
      this.showImageDialog = true;
    },
    setYoutubeLinks(links) {
      this.$set(this.news, 'youtube_links', []);
      links.forEach((l) => {
        const youtubeLink = this.$youtube.getIdFromUrl(l.link);
        if (youtubeLink) {
          this.news.youtube_links.push(youtubeLink);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

  .new-detail {
    padding: 0;
  }

  .layout {
    position: relative;

    .new__header_block {
      height: 400px;
      width: 100%;
      overflow: hidden;
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;

      @media (max-width: 960px) {
        height: 350px;
      }
      @media (max-width: 679px) {
        height: 300px;
      }

      .v-image {
        height: 100%;
      }

      .image--blur {
        filter: blur(7px) brightness(.5);
        transform: scale(1.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 679px) {
          display: none;
        }
      }

      .container {
        height: 100%;
        position: relative;

        @media (max-width: 679px) {
          padding: 0;
          ::v-deep .v-image__image {
            background-size: cover;
          }
          .new__header {
            padding: 0 10px;
          }
        }

        .image {
          filter: brightness(.6);
        }

        .new__header {
          position: absolute;
          bottom: 20px;
          color: #F8F8F8;

          h1 {
            color: #F8F8F8;
            margin-bottom: 20px;
            line-height: 1.3;

            @media (min-width: 960px) {
              font-size: 40px;
            }
          }

          p {
            color: #F8F8F8;
          }

          i {
            color: #F8F8F8;
          }
        }
      }
    }

    .new-content {
      .new-subtitle {
        font-weight: bold;
      }

      p {
        text-align: justify;
        font-size: 16px;
      }

      .new__content_line {
        width: 25%;
        margin-bottom: 15px;
        height: 2px;
        background-color: $primary-color;
        opacity: .75;

      }
    }
  }
</style>
