<template lang="pug">
  .new(:key="$route.params.id")
    the-new(:id="$route.params.id")
    other-news(:id="$route.params.id")
</template>

<script>
import TheNew from '../components/TheNew.vue';
import OtherNews from '../components/OtherNews';

export default {
  components: { OtherNews, TheNew },
};
</script>
