<template lang="pug">
  v-container.other-news(v-if="items")
    h2.mb-2 Знаете ли вы, что...

    v-list
      v-list-item.mb-2(
        v-for="item in items"
        :key="item.id"
        :to="{ name: 'new', params: { id: item.id } }"
      )
        v-list-item-content
          v-list-item-title {{item.caption}}
          v-list-item-subtitle {{item.text_short}}

</template>

<script>
import NewsService from '@/services/news';

export default {
  name: 'OtherNews',
  props: {
    newsCount: {
      type: Number,
      default: 5,
    },
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      const perPage = { 'per-page': this.newsCount + 1 };
      this.$rHandler(
        NewsService.getNews(this.nextPage, perPage),
        (response) => {
          this.items = response.data.data.filter((item) => item.id !== Number(this.id));
          this.items.length = this.newsCount;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
  .other-news {
    h2 {
      color: rgba(var(--black-color), .87);
    }

    ::v-deep .v-list__tile {
      padding: 0;

      &:hover {
        .v-list__tile__title {
          color: $primary-color;
        }
      }
    }

    .v-list__tile__title {
      color: $dark-color;
      transition: 0.3s;
    }

  }
</style>
